<template>
  <div>
    <NuxtLayout
      name="default"
      :is-primary-navbar-sticky="false"
      apply-now-type="link"
      :apply-now-url="data?.programGraduate.applyNowUrl"
      :rfi-anchor-id="pageData.rfiSectionId"
    >
      <!-- section-introduction -->
      <HeroDegreeAtlas
        id="program-intro"
        :title="data?.programGraduate.alternateTitle!"
        title-level="h1"
        title-size="medium"
        :bg-image-source="data?.programGraduate.degreeImage!"
        :next-start-date="data?.programGraduate.nextStartDate!"
        :info-list="generateInfoList"
        link-text="Learn more"
        link-url="#program-detail"
        @onLinkClick="
          scrollToDetail(
            $event,
            data?.programGraduate.alternateTitle!,
            'program-detail'
          )
        "
      >
        <template #aboveContent>
          <Alert v-if="checkDate()" show variant="info">
            <div class="d-flex flex-row align-items-center">
              <font-awesome-icon
                :icon="['fa', 'info-circle']"
                class="alert-icon"
              ></font-awesome-icon>
              <span class="fs-small fw-bold">
                {{ data?.programGraduate.webinar?.message + " " }}
                <a
                  href="#webinar-info"
                  class="rds-link"
                  @click="
                    scrollToDetail(
                      $event,
                      data?.programGraduate.webinar?.message!,
                      'webinar-info'
                    )
                  "
                  >Learn more</a
                >.
              </span>
            </div>
          </Alert>
        </template>
        <template #text>
          <div v-html="data?.programGraduate.shortDescription"></div>
        </template>
      </HeroDegreeAtlas>

      <!-- section-rfi -->
      <SectionRfiDegree
        id="program-rfi"
        :career="data?.programGraduate.category?.title!"
        :program-id="data?.programGraduate.id!"
        :program-name="data?.programGraduate.title!"
      ></SectionRfiDegree>

      <!-- section-secondary-navbar -->
      <NavbarStickyAtlas :nav-items="navbarItems">
        <template #stuckMenu>
          <div class="d-flex justify-content-end">
            <ButtonApplyNow
              type="link"
              :link-url="(data?.programGraduate.applyNowUrl as string)"
              event-region="main content"
              event-section="secondary navbar"
            ></ButtonApplyNow>

            <a
              class="btn btn-secondary fs-medium fw-bold ms-space-sm"
              href="#program-rfi"
              @click="smoothScroll"
            >
              Request Info
            </a>
          </div>
        </template>
      </NavbarStickyAtlas>

      <!-- section-detail -->
      <SectionDetail
        id="program-detail"
        :title="(data?.programGraduate.longDescriptionTitle!)"
        :description="(data?.programGraduate.longDescription!)"
      ></SectionDetail>

      <!-- section-custom-block -->
      <section
        v-if="
          data?.programGraduate.customBlockTitle ||
          data?.programGraduate.diplomaTitle
        "
        class="pb-space-xl pb-lg-space-xxl"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 offset-lg-1 col-lg-10">
              <CardInfoHorizontal
                v-if="data.programGraduate.customBlockTitle"
                border-variant="light-2"
                :bg-image-source="(data.programGraduate.customBlockImage as string)"
                :display-label="false"
                :title="data.programGraduate.customBlockTitle"
                title-size="large"
                :display-cta="false"
              >
                <div
                  class="mb-space-sm mb-lg-space-md"
                  v-html="data.programGraduate.customBlockDescription"
                ></div>
              </CardInfoHorizontal>

              <!-- section-diploma-info -->
              <CardInfoHorizontal
                v-if="data.programGraduate.diplomaTitle"
                border-variant="light-2"
                bg-image-source="//live-asuocms.ws.asu.edu/sites/default/files/image-section-diploma-info.jpg"
                :display-label="false"
                :title="data.programGraduate.diplomaTitle"
                title-size="large"
                :display-cta="false"
                class="mt-space-md"
              >
                <div
                  class="mb-space-sm mb-lg-space-md"
                  v-html="data.programGraduate.diplomaDescription"
                ></div>
              </CardInfoHorizontal>
            </div>
          </div>
        </div>
      </section>

      <!-- section webinar info -->
      <SectionIntroFalcon
        id="webinar-info"
        v-if="checkDate()"
        :title="data?.programGraduate.webinar!.title!"
        title-variant="light-1"
        section-background-variant="light-2"
        container-bg-color="dark-3"
        image-position="right"
        :image-source="data?.programGraduate.webinar!.image!"
      >
        <template #body>
          <div
            v-html="data?.programGraduate.webinar!.description"
            class="text-light-1 mb-space-sm"
          ></div>
          <div class="mb-space-md">
            <!-- put webinar date and time here -->
            <ul class="text-light-1 rds-list rds-list-secondary">
              <li class="mb-space-xs">
                <span class="fw-bold">Date: </span
                >{{
                  utilityService.formatWebinarDate(
                    data?.programGraduate.webinar?.webinarDate!,
                    "date"
                  )
                }}
              </li>
              <li>
                <span class="fw-bold">Time: </span
                >{{
                  utilityService.formatWebinarDate(
                    data?.programGraduate.webinar?.webinarDate!,
                    "time"
                  )
                }}
                (Arizona time)
              </li>
            </ul>
          </div>
          <a
            :href="data?.programGraduate.webinar?.link?.uri!"
            class="btn btn-sm btn-secondary px-space-xs py-space-xxs"
            @click="
              handleWebinarBtnClick(
                $event,
                data?.programGraduate.webinar?.title!,
                data?.programGraduate.webinar?.link as LinkType
              )
            "
          >
            {{ data?.programGraduate.webinar?.link?.text }}
          </a>
        </template>
      </SectionIntroFalcon>

      <!-- section-video -->
      <SectionVideoModal
        v-if="data?.programGraduate.videoTitle"
        :title="data?.programGraduate.videoTitle"
        :video-source="data?.programGraduate.videoUrl!"
        bg-image-source="//live-asuocms.ws.asu.edu/sites/default/files/2020-06/Ignite-66_mobile.jpg"
        @modal-open="
          analyticsComposable.trackModalEvent(
            'onclick',
            'open',
            'click',
            'main content',
            'details',
            'watch video',
            'video'
          )
        "
        @modal-close="
          analyticsComposable.trackModalEvent(
            'onclick',
            'close',
            'click',
            'main content',
            'details',
            'youtube video',
            'video'
          )
        "
      ></SectionVideoModal>

      <!-- section-courses -->
      <SectionFeaturedCourses
        v-if="(data?.programGraduate.cmsCourseItems as ParagraphFeaturedCourses[])?.length > 0"
        id="program-courses"
        :title="data?.programGraduate.featuredCoursesTitle!"
        :description="data?.programGraduate.featuredCoursesDescription!"
        :courses="(data?.programGraduate.cmsCourseItems as any)"
      ></SectionFeaturedCourses>

      <!-- section-careers -->
      <SectionRelatedCareers
        v-if="displayCareers"
        id="program-careers"
        class="mb-lg-space-xxl"
        :title="data?.programGraduate.relatedCareersTitle!"
        :description="data?.programGraduate.relatedCareersDescription!"
        :image-source="data?.programGraduate.relatedCareersImage!"
        :careers="(data?.programGraduate.cmsCareerExamples as ParagraphRelatedCareersExamples[])"
      ></SectionRelatedCareers>

      <!-- section prestigious-faculty -->
      <SectionParallaxApollo
        class="pb-space-md pb-lg-0"
        :title="data?.programGraduate.prestigiousFacultyTitle!"
        title-size="large"
        :bg-image-source="data?.programGraduate.facultyImage!"
        :display-cta="false"
      >
        <div
          v-html="data?.programGraduate.prestigiousFacultyDescription"
          class="mb-space-sm mb-lg-space-md"
        ></div>
      </SectionParallaxApollo>

      <!-- section-admission-requirement -->
      <SectionCollapseItems
        id="program-admissions"
        :title="data?.programGraduate.admissionRequirementTitle!"
        :description="data?.programGraduate.admissionRequirementDescription"
        :collapse-items="(data?.programGraduate.admissionRequirementItems as any)"
        bg-color="light-2"
        event-component="admission requirements"
      ></SectionCollapseItems>

      <!-- section-accolades -->
      <SectionProgramAccolades
        id="program-accolades"
        class="pt-0 pb-space-xxs px-lg-space-xxxl pt-lg-space-xxl mb-0"
        :title="data?.programGraduate.accoladesTitle!"
        :description="data?.programGraduate.accoladesDescription"
        :items="(data?.programGraduate.accoladeItems as any)"
      ></SectionProgramAccolades>

      <!-- section-cost -->
      <SectionCost
        id="program-cost"
        class="pt-space-xxl"
        :title="pageData.sectionCost.title"
        :text="pageData.sectionCost.description"
        :program-category="data?.programGraduate.category?.title!"
        :cost-override="data?.programGraduate.costOverride!"
        :cost-academic-year="data?.programGraduate.costAcademicYear!"
        :cost-resident-url="data?.programGraduate.costResidentUrl!"
        :cost-non-resident-url="data?.programGraduate.costNonresidentUrl!"
        :cost-collection="(data?.programGraduate.costCollection as any)"
        degree-type="Graduate"
      ></SectionCost>

      <!-- section-faq -->
      <SectionCollapseItems
        v-if="data?.programGraduate.faqTitle && (data?.programGraduate.faqItems as ParagraphAccordionText[])?.length > 0"
        id="program-faq"
        :title="data?.programGraduate.faqTitle"
        :collapse-items="(data?.programGraduate.faqItems as any)"
        event-component="faq"
      ></SectionCollapseItems>

      <!-- section-interested-programs -->
      <SectionInterestedPrograms
        :degrees="(data?.programGraduate.interestedPrograms as DegreeSeekingProgram[])"
      ></SectionInterestedPrograms>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import Alert from "@/components/Alert.vue";
import SectionCollapseItems from "@/components/SectionCollapseItems.vue";
import HeroDegreeAtlas from "@/components/degree-page/HeroDegreeAtlas.vue";
import SectionCost from "@/components/degree-page/SectionCost.vue";
import SectionDetail from "@/components/degree-page/SectionDetail.vue";
import SectionFeaturedCourses from "@/components/degree-page/SectionFeaturedCourses.vue";
import SectionInterestedPrograms from "@/components/degree-page/SectionInterestedPrograms.vue";
import SectionProgramAccolades from "@/components/degree-page/SectionProgramAccolades.vue";
import SectionRelatedCareers from "@/components/degree-page/SectionRelatedCareers.vue";
import ButtonApplyNow from "@/components/nav/ButtonApplyNow.vue";
import SectionRfiDegree from "@/components/rfi/SectionRfiDegree.vue";
import type {
  DegreeSeekingProgram,
  ParagraphAccordionText,
  ParagraphFeaturedCourses,
  ParagraphRelatedCareersExamples,
  ProgramGraduate,
} from "@/server/resolvers/resolvers-types";
import { scrollToTarget } from "@/utils";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { SectionVideoModal } from "@rds-vue-ui/section-video-modal";
import { useWindowSize } from "@vueuse/core";

interface PageQueryType {
  programGraduate: ProgramGraduate;
}

const route = useRoute();
const data = await usePageQuery<PageQueryType>(
  `#graphql
      query ($slug: String!){
        programGraduate(slug: $slug){
          id
          detailPage
          code
          title
          alternateTitle
          shortDescription
          longDescriptionTitle
          longDescription
          weeksPerClass
          totalClasses
          totalCreditHours
          nextStartDate
          applyNowUrl
          metaTagTitle
          metaTagDescription
          prestigiousFacultyTitle
          prestigiousFacultyDescription
          facultyImage
          videoTitle
          videoUrl
          degreeImage
          category{
            title
            slug
          }
          featuredCoursesTitle
          featuredCoursesDescription
          cmsCourseItems{
            title
            category
            description
            youtubeVideoId
            youtubeVideoDuration
            youtubeVideoUploadDate
          }
          relatedCareersTitle
          relatedCareersDescription
          relatedCareersImage
          cmsCareerExamples{
            title
            description
          }
          admissionRequirementTitle
          admissionRequirementDescription
          admissionRequirementItems{
            title
            description
          }
          accoladesTitle
          accoladesDescription
          accoladeItems{
            title
            description
          }
          faqTitle
          faqDescription
          faqItems{
            title
            description
          }
          customBlockTitle
          customBlockDescription
          customBlockImage
          costOverride
          costAcademicYear
          costResidentUrl
          costNonresidentUrl
          costCollection{
            amount
            summary
          }
          interestedPrograms{
            id
            title
            nextStartDate
            category{
              title
            }
            detailPage
            degreeImage
          }
          diplomaTitle
          diplomaDescription
          webinar {
            title
            message
            image
            description
            webinarDate
            link {
              text
              target
              uri
            }
          }
        }
      }
  `,
  {
    slug: route.params.slug as string,
  },
  {
    key: (route.params.slug as string) + "-grad-data",
  }
);

const config = useRuntimeConfig();
const siteUrl = config.public.siteUrl;
const utilityService = useUtilityService();
const schemaService = useSchemaService();
const { width } = useWindowSize();
try {
  const JSONLdSchema = schemaService.generateProgramTemplateSchema(
    `${siteUrl}${data?.programGraduate.detailPage}`,
    data?.programGraduate,
    data?.programGraduate?.cmsCourseItems &&
      data?.programGraduate.cmsCourseItems !== undefined
      ? (data?.programGraduate.cmsCourseItems as ParagraphFeaturedCourses[])
      : [],
    "graduate"
  );
  useHead({
    title: data?.programGraduate.metaTagTitle,
    meta: [
      { name: "Title", content: data?.programGraduate.metaTagTitle },
      {
        name: "Description",
        content: data?.programGraduate.metaTagDescription,
      },
      ...utilityService.generateSocialTags(
        data?.programGraduate.metaTagTitle!,
        data?.programGraduate.metaTagDescription!,
        data?.programGraduate.degreeImage!
      ),
    ],
    link: [
      {
        rel: "canonical",
        href: `${siteUrl}${data?.programGraduate.detailPage}`,
      },
    ],
    script: [JSONLdSchema],
  });
} catch (error) {
  console.error("=== Error in graduate slug");
  console.error(error);
}
definePageMeta({
  layout: false,
  __dangerouslyDisableSanitizersByTagID: {
    "ldjson-schema": ["innerHTML"],
  },
});

const pageData = reactive({
  rfiSectionId: "program-rfi",
  sectionCost: {
    title: "Tuition calculator",
    description:
      '<p>Use our calculator to estimate your full-time or part-time tuition fees for this program prior to any financial aid. Keep in mind that most of our students receive financial aid, which can reduce out-of-pocket costs. <a href="/what-it-costs/financial-aid/" class="non-rds-link">Learn more.</a></p>',
  },
});

const displayCareers = computed(() => {
  return (
    data?.programGraduate.cmsCareerExamples &&
    data?.programGraduate.cmsCareerExamples.length > 0 &&
    data?.programGraduate.relatedCareersImage
  );
});

const navbarItems = computed(() => {
  interface NavItemsContent {
    [key: string]: string;
  }

  let navbarItems: NavItemsContent = {};

  navbarItems["program-intro"] = "Overview";
  navbarItems["program-detail"] = "Details";

  if (
    data?.programGraduate &&
    data?.programGraduate?.cmsCourseItems &&
    data?.programGraduate.cmsCourseItems?.length > 0
  ) {
    navbarItems["program-courses"] = "Courses";
  }

  if (displayCareers.value) {
    navbarItems["program-careers"] = "Careers";
  }

  navbarItems["program-admissions"] = "Admissions";
  navbarItems["program-accolades"] = "Accolades";
  navbarItems["program-cost"] = "Tuition";

  return navbarItems;
});
const generateInfoList = computed(() => {
  return [
    {
      value: data?.programGraduate.totalClasses!,
      description: "Total classes: ",
    },
    {
      value: data?.programGraduate.weeksPerClass!,
      description: "Weeks per class: ",
    },
    {
      value: data?.programGraduate.totalCreditHours!,
      description: "Total credit hours: ",
    },
  ];
});
const scrollToDetail = (e: Event, section: string, target: string) => {
  e.preventDefault();

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    "learn more"
  );

  let navbarOffset = 59;
  if (width.value < 992) {
    navbarOffset = 65;
  }

  scrollToTarget(target, { offset: navbarOffset });
};
const smoothScroll = (e: Event) => {
  e.preventDefault();
  const eTarget = e.target as HTMLLinkElement;
  const eventText = eTarget.textContent
    ? eTarget.textContent.toLowerCase()
    : "N/A";
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "secondary navbar",
    eventText,
    "secondary navbar"
  );
  let navbarOffset = 59;
  if (width.value < 992) {
    navbarOffset = 65;
  }
  const href = eTarget.getAttribute("href");

  scrollToTarget(href?.slice(1), { offset: navbarOffset });
};
const checkDate = () => {
  if (data?.programGraduate!.webinar) {
    const currentTime = new Date(new Date().toDateString()).getTime();
    const webinarDate = new Date(
      new Date(data?.programGraduate!.webinar.webinarDate!).toDateString()
    ).getTime();
    const checkTime = webinarDate - currentTime >= 0;
    return checkTime;
  }
  return false;
};
interface LinkType {
  text: string;
  uri: string;
}
const handleWebinarBtnClick = async (
  e: Event,
  section: string,
  link: LinkType
) => {
  e.preventDefault();

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    link.text.toLowerCase()
  );

  await navigateTo(link.uri, {
    open: {
      target: "_blank",
    },
  });
};
</script>

<style scoped lang="scss">
.alert-icon {
  font-size: 2rem;
  margin-right: 24px;
}

:deep(.bg-backdrop) {
  margin-top: 0px;
}
</style>
